import { extractUrlParams } from "./index"

export enum AppClientCodes {
	MERCHANT_DASHBOARD_CLIENT = "merchant-dashboard-client",
	ONBOARDINGV2 = "onboardingv2",
	ONBOARDING_FRONTEND = "onboarding-frontend",
	SOLO_ADAPTER_CLIENT = "solo-adapter-client",
	ANDROID_APP_CLIENT = "android-app-client",
	IOS_APP_CLIENT = "ios-app-client",
	IK_WEBSITE = "ik-website",
}

export function navigateToForgotPasswordPage(appCode: string, redirectUri: string | undefined, callbackUrl: string) {
	const baseUrl = "/forgotpassword"
	const queryParams = [
		`appCode=${appCode}`,
		`redirectUri=${encodeURIComponent(redirectUri ?? "")}`,
		`callbackUrl=${encodeURIComponent(callbackUrl ?? "")}`,
	]
	window.location.href = `${baseUrl}?${queryParams.join("&")}`
}
/**
 * Generates a redirect URL based on the provided parameters and the current full URL.
 *
 * - For mobile clients (such as Android and iOS apps), the function appends `state=success` to the current full URL.
 * - For non-mobile clients, the function appends the `redirectUri` as a query parameter to the provided `callbackUrl`.
 *
 * @param {string} callbackUrl - The base URL to which the redirectUri should be appended for non-mobile clients.
 * @param {string} appCode - The code representing the application/client type. This is used to determine the redirect behavior.
 * @param {string} [redirectUri] - An optional redirect URI. If provided and the appCode is non-mobile, it will be appended to the callbackUrl.
 *
 * @returns {string} The generated redirect URL.
 */

export function generateRedirectUrlForPlatform(callbackUrl: string, appCode: string, redirectUri?: string): string {
	//  handle mobile app clients for 'signIn' event
	if (isMobileApp(appCode as AppClientCodes)) {
		//  redirect to login and set success.
		return setMobileStateSuccess()
	} else {
		return generateWebCallbackUrl(callbackUrl, redirectUri)
	}
}

// handling onboarding fragment hashing
const CALLBACK_BASE_URL = process.env.REACT_APP_IK_ONBOARDING_URL
// The hash fragment to append
const HASH_FRAGMENT = "#/info-message-welcome"

// Function to update the callback URL
export function updateCallbackUrl(callbackUrl) {
	if (callbackUrl) {
		// Decode the URL
		callbackUrl = decodeURIComponent(callbackUrl)

		// Check if the callbackUrl starts with the CALLBACK_BASE_URL
		if (callbackUrl.startsWith(CALLBACK_BASE_URL)) {
			// Append the hash fragment if it's not already in the callbackUrl
			if (!callbackUrl.includes(HASH_FRAGMENT)) {
				callbackUrl += HASH_FRAGMENT
			}
		}

		// Return the updated URL, not encoded
		return callbackUrl
	}
	// If callbackUrl is not provided, return it as is
	return callbackUrl
}

export function buildUrlWithUpdatedCallback(basePath, searchString) {
	const urlParams = new URLSearchParams(searchString)

	// Extract the 'callbackUrl' parameter
	let callbackUrl = urlParams.get("callbackUrl")
	if (callbackUrl) {
		// Update the callback URL
		const updatedCallbackUrl = updateCallbackUrl(callbackUrl)

		// Set the updated callback URL back into the URL parameters
		urlParams.set("callbackUrl", updatedCallbackUrl)
	}

	// Construct the final URL
	return `${basePath}?${urlParams.toString()}`
}

// Function to determine if the current client is a mobile app
export function isMobileApp(clientCode: AppClientCodes): boolean {
	return clientCode === AppClientCodes.IOS_APP_CLIENT || clientCode === AppClientCodes.ANDROID_APP_CLIENT
}

export function isWebApp(clientCode: AppClientCodes): boolean {
	return !isMobileApp(clientCode)
}

export function removeQueryParam(searchString, paramToRemove) {
	const urlParams = new URLSearchParams(searchString)
	urlParams.delete(paramToRemove)
	return urlParams.toString()
}

function setMobileStateSuccess(): string {
	// Extract the protocol and host to construct the base URL for the /signin page.
	const protocol = window.location.protocol
	const host = window.location.host
	let signInBaseUrl = `${protocol}//${host}/signin`

	// Extract existing query parameters.
	let queryParams = new URLSearchParams(window.location.search)

	// Check if the 'state' parameter already exists.
	if (queryParams.has("state")) {
		// If it exists, delete it first.
		queryParams.delete("state")
	}

	// Now, set (or re-set) the 'state' parameter to 'success'.
	queryParams.set("state", "success")

	// Construct the full URL for redirection to /signin, preserving existing query parameters.
	let redirectUrl = `${signInBaseUrl}?${queryParams.toString()}`

	// Return the redirect URL.
	return redirectUrl
}

function generateWebCallbackUrl(callbackUrl: string, redirectUri?: string): string {
	return `${callbackUrl}?redirectUri=${encodeURIComponent(redirectUri ?? "")}`
}

export const validateEmailFormat = (emailToValidate) => {
	const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/
	return re.test(emailToValidate)
}

export function getPasswordValidation(password) {
	return {
		length: password.length >= 8,
		upperCase: /[A-Z]/.test(password),
		lowerCase: /[a-z]/.test(password),
		number: /\d/.test(password),
		specialChar: /[!@#$%^&*()\-[\]]/.test(password),
	}
}

export function validatePasswordFormat(password) {
	const validation = getPasswordValidation(password)
	const isValid = Object.values(validation).every((v) => v === true)
	return isValid
}

export const validateEmail = (email) => {
	let tempErrors: string[] = []

	if (!validateEmailFormat(email)) {
		tempErrors.push("Please Enter a Valid Email Address")
	}
	return tempErrors
}

export const validateConfirmEmail = (email, confirmEmail) => {
	let tempErrors: string[] = []

	if (!validateEmailFormat(confirmEmail)) {
		tempErrors.push("Please Enter a Valid Email Address")
	} else if (email !== confirmEmail) {
		tempErrors.push("Email Don't Match")
	}

	return tempErrors
}

export const validatePassword = (password) => {
	let tempErrors: string[] = []

	if (!validatePasswordFormat(password)) {
		tempErrors.push("Password requirements not met.")
	}

	return tempErrors
}

export const validateWeekPassword = (password: string): string[] => {
	let tempErrors: string[] = []

	// Check if the password is not empty
	if (!password.trim()) {
		tempErrors.push("Password requirements not met")
	}

	return tempErrors
}

export const extractAndValidatePlatform = (location: string): string | undefined => {
	// Extract URL parameters
	const { platform } = extractUrlParams(location)

	// Define the valid platforms
	const validPlatforms = ["Android", "iKFlyer"]

	// Check if the platform is valid
	if (platform && validPlatforms.includes(platform)) {
		return platform
	}
	// Return undefined if conditions are not met
	return undefined
}

export function validateAndNormalizePhoneNumber(phoneNumber) {
	// Remove all non-numeric characters except the leading plus
	let cleanedNumber = phoneNumber.replace(/[^\d+]/g, "")

	// Define the regex pattern for South African phone numbers
	const pattern = /^(?:\+27|0)(?:6\d|7[0-4]|7[6-9]|8[1-4])\d{7}$/

	// Check if the cleaned number matches the South African phone number pattern
	if (pattern.test(cleanedNumber)) {
		// Normalize to international format if it starts with '0'
		if (cleanedNumber.startsWith("0")) {
			cleanedNumber = "+27" + cleanedNumber.substring(1)
		}
		return {
			isValid: true,
			phoneNumber: cleanedNumber,
			error: null,
		}
	} else {
		// If the number does not match the pattern, it is invalid
		return {
			isValid: false,
			phoneNumber: "",
			error: "Invalid South African phone number format.",
		}
	}
}

/**
 * temp redirect to onboarding signup
 */
export const redirectTempToOnboardingSignUp = () => {
	window.location.replace(process.env.REACT_APP_IK_ONBOARDING_URL || "")
}

export function handleErrorResponse(error) {
	const message = getErrorMessage(error)
	return { success: false, message: message }
}

export function getErrorMessage(error) {
	let message

	// Check if it's an Axios error with a response and the status is 403
	if (error?.response?.status === 403) {
		// Check if the parsed data has the specific message
		message = "Unauthorized"
	} else if (error?.response?.data) {
		// If the server responded with a message and it's not a 403 error
		message = error.response.data.message || error.response.data.error || "An unknown error occurred."
	} else {
		// If there is no response from the server, use the default error message
		message = error?.message || "An unknown error occurred."
	}

	return message
}

/**
 * Get the sign-up URL based on the application's configuration.
 * If onboarding redirection is enabled, use the onboarding URL from environment variables.
 * Otherwise, build the URL with an updated callback based on the current location.
 * @returns {string} The sign-up URL.
 */
export function getSignUpUrl() {
	const isOnboardingEnabled = process.env.ENABLE_ONBOARDING_REDIRECT === "true"
	const onboardingUrl = process.env.REACT_APP_IK_ONBOARDING_URL

	if (isOnboardingEnabled && onboardingUrl) {
		return onboardingUrl
	} else if (!isOnboardingEnabled) {
		return buildUrlWithUpdatedCallback("/signup", window.location.search)
	} else {
		// Handle the case where the onboarding URL is not set properly
		console.error("Onboarding URL is not set in environment variables.")
		// Return a fallback URL or handle the error appropriately
		return "/error"
	}
}

export function prepareUserData(signUpData, channel) {
	// Extract necessary fields from signUpData, apply defaults or transformations as needed
	const userData = {
		cognitoid: signUpData.username ?? "",
		first_name: "",
		last_name: "",
		signup_email: signUpData.email ?? "",
		signup_phone: signUpData.phoneNumber ?? "",
		channel: channel ?? "",
	}
	return userData
}

export async function callbackRedirect() {
	const { appCode, callbackUrl, redirectUri } = extractUrlParams(window.location.search)
	window.location.replace(generateRedirectUrlForPlatform(callbackUrl, appCode, redirectUri))
}
